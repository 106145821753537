.slick-prev,
.slick-next {
  color: #000;
}

.slick-prev:hover,
.slick-next:hover {
  color: #000;
}

.carousel-container {
  width: 100%;
  background-color: #f1f1f1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.pre-carousel-container {
  width: 100%;
  text-align: center;
  background-color: #f1f1f1;
  padding: 50px;
}

.carousel {
  width: 60%;
  position: relative;
}

.slick-slide > div {
  margin-right: 20px;
}

.event {
  width: 100%;
  height: 100%;
  /* background-color: #f1f1f1; */
  position: relative;
  border-radius: 10px;
}

.slick-slide > div:hover {
  border-radius: 10px;
  border: 6px solid rgb(0, 102, 255);
}

.slick-center > div {
  border-radius: 10px;
  border: 6px solid rgb(0, 102, 255);
}

.event img {
  border-radius: 1px;
  width: 100%;
  height: 100%;
}

.event-child {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  bottom: 0;
  width: 100%;
  margin: 0px;
  border-radius: 10px;
  padding: 10px;
  background: linear-gradient(#ffffff00, 30%, var(--color-area));
}

.location-carousel {
  display: flex;
  justify-content: left;
  gap: 5px;
}

.right-content {
  width: 45%;
  margin: 30px;
  padding: 10px;
  /* background-color: red; */
}

.right-content h2 {
  color: rgb(0, 102, 255);
}

.right-content-container {
  width: 100%;
  /* background-color: blue; */
}

.right-date-location-container {
  display: flex;
  justify-content: space-between;
}

.right-date-location {
  display: flex;
  justify-content: center;
  gap: 5px;
}

.moredetails {
  text-align: center;
  margin: 20px;
}

.moredetails button {
  color: #fff;
  cursor: pointer;
  padding: 10px 50px 10px 50px;
  background-color: rgb(62, 156, 62);
  border: none;
  border-radius: 10px;
}

.right-without-event {
  text-align: center;
}

.img-carrusel{
  display: inline-table !important;
}

@media (max-width: 767px) {
  .slick-prev:before,
  .slick-next:before {
    font-size: 0px !important;
  }
  .slick-prev,
  .slick-next {
    width: 0% !important;
  }
  .carousel { width: 100%; }
}

#nextID
{
  position: absolute;
  width: 46px;
  height: 100%;
  background: linear-gradient(90deg, transparent, white);
  z-index: 1;
  top: 0;
  right: 0;
}

#prevID
{
  position: absolute;
  width: 46px;
  height: 100%;
  background: linear-gradient(90deg, white, transparent );
  z-index: 1;
}

.slick-prev
{
  left: 0;
}

.slick-next
{
  right: 0;
}