body {
  font-family: "Raleway", sans-serif;
}

/* Footer */

.bottom_footer {
  font-size: 10px;
  line-height: 14px;
  font-weight: 500;
}

.inline-menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer__link {
  display: inline-block;
}

.inline-menu li a {
  margin: 8px;
}

.link-icon {
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-box-pack: center;
  display: inline-block;
  vertical-align: middle;
  width: 26px;
  height: 27px;
  background-size: contain;
}

.siguenos-contacto {
  font-size: 12px;
}

.footer_links {
  font-size: 0.95rem;
  font-weight: 700;
}

.content_links {
  line-height: normal;
}

.footer_miembros {
  width: 13.5%;
  display: inline-block;
}

.footer_miembros_img {
  height: 42px;
  margin: 16px;
}

@media (max-width: 767.98px) {
  .footer_miembros {
    width: 32.5%;
    display: inline-block;
  }

  .footer_links {
    font-size: 0.75rem;
    font-weight: 700;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .footer_miembros {
    width: 24.5%;
    display: inline-block;
  }
}

.logo {
  max-height: 45px;
}

.siguenos-contacto {
  font-size: 12px;
}

footer a {
  text-decoration: none;
}

.txt-ver {
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  font-size: 15px;
}

.txt-agenda {
  font-size: 14px;
}

.sub-link {
  text-decoration: none;
  font-size: 13px;
  color: #ababab !important;
}

.text-u {
  font-size: 12px;
}

.bg-dark {
  background-color: #212121 !important;
}

.responsive-text {
  text-align: center;
  margin-top: 13% !important;
}

@media (min-width: 768px) {
  .responsive-text {
    text-align: left;
    margin-top: 1% !important;
  }
}