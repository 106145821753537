.titreg {
  margin: 2;
  position: absolute;
  padding-bottom: 3%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: rgba(67, 161, 78, 255);
  color: white;
  font-weight: normal;
  font-size: 18px;
  text-align: center;
  border-radius: 10px;
  border: rgba(67, 161, 78, 255);
  padding-top: 0.5%;
  padding-bottom: 0.5%;
  padding-left: 5%;
  padding-right: 5%;
}

.titregL {
  background: var(--colorBtn);
  color: white;
  font-weight: normal;
  font-size: 18px;
  text-align: center;
  border-radius: 10px;
  border: var(--colorBtn);
  padding-top: 0.5%;
  padding-bottom: 0.5%;
  padding-left: 5%;
  padding-right: 5%;
}

.sendBottom {
  margin: 2;
  transform: translate(-50%, -50%);
  background: rgba(13, 125, 237, 1);
  color: white;
  font-weight: normal;
  font-size: 18px;
  text-align: center;
  border-radius: 10px;
  border: rgba(13, 125, 237, 1);
  padding-top: 1.5%;
  padding-bottom: 1.5%;
  padding-left: 6%;
  padding-right: 6%;
}

.ValidateBottom {
  padding-bottom: 3%;
  background: rgba(13, 125, 237, 1);
  color: white;
  font-weight: normal;
  font-size: 18px;
  text-align: center;
  border-radius: 10px;
  border: rgba(13, 125, 237, 1);
  padding-top: 0.5%;
  padding-bottom: 0.5%;
  padding-left: 5%;
  padding-right: 5%;
}

.errorForm {
  color: #b70404;
}
.bloqueado {
  opacity: 0.5;
}
