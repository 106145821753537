.cuerpo {
  margin-left: 8%;
  margin-right: 8%;
  margin-top: 2%;
}

.imageformat {
  height: 400px;
  width: 400px;
  border-radius: 10px;
}

.bluetext {
  font-family: "Helvetica";
  font-weight: lighter;
  margin-top: 4%;
  color: rgb(13, 125, 237);
  /* font-size: 22px; */
}

.blacktext {
  font-family: "Helvetica";
  font-weight: lighter;
  color: black;
  font-size: 20px;
  margin: 0.1%;
}

.gerytext{
  
  font-family: "Helvetica";
  font-weight: lighter;
  color: #6c757d;
  font-size: 20px;
  margin: 0.1%;
}

.ubi {
  width: 100%;
}

.frameDisenio{
  border-radius: 24px;
    overflow: hidden;
}


.titleEvent {
  font-family: "Helvetica";
  font-weight: normal;
  font-size: 30px;
  padding-left: 1%;
  padding-right: 1%;
}

.inline-container {
  display: flex;
  align-items: flex-start;
}

.desEvent {
  font-family: "Helvetica";
  font-weight: light;
  font-size: 20px;
  padding-left: 1%;
  padding-right: 1%;
}

.fechaEvent {
  font-family: "Helvetica";
  font-weight: bold;
  font-size: 20px;
  margin-left: 5px;
}

.header_date_location_area {
  color: rgb(13, 125, 237);
  font-weight: 200;
  font-size: 20px;
}

.main_information {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main_information_information {
  width: 70%;

  padding: 20px;
}

.main_information_picture {
  width: 30%;

  padding: 20px;
}

.hour_date_place_information {
  width: 100%;

  padding: 20px;
}

.hour_date_container,
.place_container,
.meeting_link_container {
  display: flex;
  justify-content: flex-start;
}
.info-container {
  display: flex;
}

.contact_information {
  width: 100%;
}

.tituloInfo{
  letter-spacing: -0.48px;
    font-size: 23px;
    color: #109AFF;
    font-weight: normal;
}

.borderForm{
box-shadow: 4px 9px 8px #00000029;
border: 2px solid #C6C3C3;
border-radius: 27px;
}