/* Estilos para hacer el componente responsivo */
.responsive-card {
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  width: 100%;
  display: flex;
  background-color: white;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.responsive-image {
  width: 85%;
  margin-top: 20px;
  border-radius: 5px;
}

.responsive-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 2%;
  padding-right: 2%;
  align-items: initial;
  height: 100%;
}

.evento-title {
  margin-bottom: 10px;
  font-size: 22px;
}

.location-box {
  display: flex;
  justify-content: initial;
  margin-bottom: 0.5px;
  margin-top: 0.5px;
  white-space: nowrap;
  overflow: hidden;
}

.location {
  text-overflow: ellipsis;
  overflow: hidden;
}

.button-box {
  display: flex;
  justify-content: initial;
  margin-top: 20px;
}
