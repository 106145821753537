.tituloEvento {
  font-family: Helvetica !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 15px !important;
  letter-spacing: 0px !important;
  line-height: 25px !important;
}

.ubicacionEvento {
  font-family: Helvetica !important;
  font-style: normal !important;
  font-weight: 100 !important;
  font-size: 15px !important;
  letter-spacing: 0px !important;
  line-height: 25px !important;
}

.colour_1 {
  background-color: #831c65 !important;
}

/* INPUTS */
:focus {
  outline: none;
}

/* necessary to give position: relative to parent. */
.inputText {
  font: 15px/24px "Muli", sans-serif;
  color: #333;
  width: 100%;
  box-sizing: border-box;
  letter-spacing: 1px;
}

:focus {
  outline: none;
}

.col-3 {
  float: left;
  width: 27.33%;
  margin: 40px 3%;
  position: relative;
} /* necessary to give position: relative to parent. */
.inputText {
  font: 15px/24px "Lato", Arial, sans-serif;
  color: #333;
  width: 100%;
  box-sizing: border-box;
  letter-spacing: 1px;
}

.effect-10 {
  border: 0;
  padding: 7px 15px;
  border: 1px solid #ccc;
  position: relative;
  background: transparent;
}

.effect-10 ~ .focus-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #ededed;
  opacity: 0;
  transition: 0.5s;
  z-index: -1;
}
.effect-10:focus ~ .focus-bg {
  transition: 0.5s;
  opacity: 1;
}
/* INPUTS */
