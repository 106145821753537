.contenedor {
  width: 100%;
  color: #fff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  /* background-size:contain;
  background-repeat: no-repeat;
  background-position: center; */
  height: 25rem;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  height: 25rem;
  background-attachment: fixed;
  background-position: center;
}

.divobscuro {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  width: 100%;
  height: 100%;
}

.contenefinal {
  width: 80%;
  padding: 50px;
  z-index: 1;
}

.titulo {
  margin: 0;
  font-size: xx-large;
}

.contenido {
  font-size: larger;
}

.descubremas {
  margin: 20px;
}

.descubremas button {
  color: #fff;
  cursor: pointer;
  padding: 20px 25px 20px 25px;
  background-color: #3b86ff;
  border: none;
  border-radius: 10px;
}

/* .contenedor img {
    width: 100%;
    height: 400px;
    filter: brightness(0.7);
} */

/* .contenido {
    position: absolute;
    width: 80%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
} */

.buscador {
  display: flex;
  gap: 20px;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
}

.buscador > input {
  width: 80%;
  border-radius: 20px;
  padding: 10px;
}

.botonbuscar {
  width: 20%;
  display: flex;
  justify-content: center;
}

.botonbuscar button {
  cursor: pointer;
  display: flex;
  align-items: center;
  background: #000;
  border-radius: 10px;
  color: #fff;
  padding: 5px 20px 5px 20px;
  border: none;
  gap: 5px;
}

.searchicon {
  display: flex;
  align-items: center;
}
