/* Estilo para la superposición */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9); /* Fondo blanco semitransparente */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Asegura que esté por encima de todo */
}

/* Estilo para el spinner */
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.3); /* Borde transparente */
  border-top: 4px solid #000; /* Color del spinner (negro) */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite; /* Animación de giro */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
